export enum FormBuilderControlTypes {
  description = 1,
  textInput,
  numberInput,
  multilineInput,
  date,
  dropDown,
  radioButton,
  checkbox,
  checkboxGroup,
  eyEmailLookup,
  divider,
  rating,
}
