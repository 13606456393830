import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';

export enum RATING_TYPES {
  Star = 'Star',
  Number = 'Number',
}

export const DEFAULT_MAX_RATING = 5;
export const DEFAULT_RATING_TYPE = RATING_TYPES.Star;

@Component({
  selector: 'ey-display-rating',
  templateUrl: './ey-display-rating.component.html',
  styleUrls: ['./ey-display-rating.component.scss'],
})
export class EyDisplayRatingComponent implements OnInit {
  @Input() isDisabled = false;
  @Input() isReadOnly = false;
  @Input() maxRating;
  @Input() ratingType: RATING_TYPES;
  @Input() ctrl_id: number;

  @Output() onRatingChange = new EventEmitter<number>();

  ratingTypes = RATING_TYPES;
  _selectedRating: number = 0;
  _ratings: number[] = [];

  constructor() {}

  get selectedRating(): number {
    return this._selectedRating;
  }

  set selectedRating(value: number) {
    this._selectedRating = value;
  }

  get ratings(): number[] {
    return this._ratings;
  }

  ngOnInit(): void {
    if (this.isReadOnly) {
      this.selectedRating = this.maxRating;
    }

    this.maxRating = this.maxRating ? this.maxRating : DEFAULT_MAX_RATING;
    this.ratingType = this.ratingType ? this.ratingType : DEFAULT_RATING_TYPE;

    this._ratings = Array(+this.maxRating)
      .fill(0)
      .map((x, i) => i + 1);
  }

  onRating(rating: any): void {
    this.selectedRating = rating;
    this.onRatingChange.emit(this.selectedRating);
  }
}
