<div class="d-flex">
  <div class="numeric-rating" *ngIf="ratingType === ratingTypes.Number">
    <span
      [class.read-only]="isDisabled"
      [id]="'rat' + ctrl_id"
      class="rating-box"
      *ngFor="let rating of ratings"
      (click)="onRating(rating)"
      (keyup.enter)="onRating(rating)"
      [class.selected]="rating === selectedRating"
      [tabindex]="isDisabled ? -1 : 0"
    >
      {{ rating }}
    </span>
  </div>
  <div class="star-rating" *ngIf="ratingType === ratingTypes.Star">
    <span
      [class.read-only]="isDisabled"
      [id]="'rat' + ctrl_id"
      class="star"
      *ngFor="let rating of ratings"
      (click)="onRating(rating)"
      [class.filled]="rating <= selectedRating"
      (keyup.enter)="onRating(rating)"
      [tabindex]="isDisabled ? -1 : 0"
    >
      &#9733;
    </span>
  </div>
</div>
